import { Component } from "react";

import "../style/Home.css";
import Slideshow from "./SlideShow";
import o2 from "../images/slika_objekta.jpg";

const Home = () => {
    return(
        <section className="container-fluid bg-primary p-0 overflow-hidden" id="home">
            <div className="row g-0 align-items-center flex-column-reverse flex-lg-row">
                <div className="col-lg-6">
                    <div className="home-text">
                        <h1 className="title display-4 text-white">Lečenje <span>kiseonikom</span> u <span>hiperbaričnoj komori</span></h1>
                        <p className="subtitle">Nudimo sve vrste specijalističkih pregleda, konsultacija,
                        lečenja kao i post terapijsko praćenje pacijenata iz oblasti Ortopedije i Baromedicine</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="navigation">
                        <Slideshow />
                    </div>
                </div> 
            </div> 
        </section>
    );
}

export default Home;