import { Component } from "react";
import "../style/Overview.css";


const Overview = () => {
    return(
        <section className="container-fluid p-0 Overview section">
            <div className="container mt-3 mb-3">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>Lečenje Najsavremenijim Hiperbaričnim Komorama</h2>
                            <p>Jednostavna usluga tretmana čistim kiseonikom</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="single-feature">
                            <div className="single-icon">
                                <i class="bi bi-clipboard2-check icon"></i>
                            </div>
                            <h3 className="text-center">Pregled</h3>
                            <p className="text-center">Pregled analiza i dokumentacija</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-12">
                        <div className="single-feature">
                            <div className="single-icon">
                                <i class="bi bi-file-text icon"></i>
                            </div>
                            <h3 className="text-center">Priprema</h3>
                            <p className="text-center">Uvođenje u postupak lečenja</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-12">
                        <div className="single-feature last">
                            <div className="single-icon">
                            <i class="bi bi-clipboard2-heart icon"></i>
                            </div>
                            <h3 className="text-center">Lečenje</h3>
                            <p className="text-center">Praćenje stanja tokom celog tretmana</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Overview;