import "../style/KojeBolestiLeci.css";

const KojeBolestiLeci = () => {
    return(
            <section className="why-choose" id="cure">
                <div className="container">

                    <div className="container mt-3 mb-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>Lečenje velikog broja bolesti i povreda</h2>
                                    <p>Široka primena tretmana kiseonikom</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="choose-left">
                                    <h3 className="">Lek za telo</h3>
                                    <p className="">Primenom savremene terapije čistim kiseonikom u hiperbaričnoj komori omogućuje pacijentima da na bezbedan i prirodan način leče veliki broj bolesti i povreda, uključujući:</p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul>
                                                <li><i class="bi bi-caret-right-fill"></i>Hronične rane (ulkusi)</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Hronične upale kostiju (osteomyeliti)</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Dijabetične gangrene</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Dijabetesno stopalo</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Srčana bolest, post infarktna stanja, baipasevi</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Birgerova bolest, Rejnova bolest</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Morbus Sudeck (posledica nošenja gipsa)</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Odloženo srašćivanje kostiji (preloma)</li>
                                                <li><i class="bi bi-caret-right-fill"></i>Opekotine, promrzline, ugroženi kožni režnjevi</li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-6">
                                        <ul>
                                            <li><i class="bi bi-caret-right-fill"></i>Rane nakon zračne terapije</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Akutna reumatska upala zglobova</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Sklerodermia</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Multipla skleroza</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Čir na želucu i dvanaestopalačnom crevu</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Kronova bolest, ulcerozni kolitis</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Srčana bolest, post infarktna stanja, baipasevi</li>
                                            <li><i class="bi bi-caret-right-fill"></i>Intervencije na krvnim sudovima</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-12">
                                <div class="choose-right-o2"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
}

export default KojeBolestiLeci;

/*
           <div className="koje-bolesti-leci-section">
                <div className="koje-bolesti-leci-sadrzaj">
                    <h2 className="koje-bolesti-leci-title">Koje bolesti leči hiperbarična komora?</h2>
                    <div className="sadrzaj">
                        <ul id="lista-bolesti">
                            <li>Hronične rane(ulkusi)</li>
                            <li>Hronične upale kostiju(osteomyeliti)</li>
                            <li>Dijabetične gangrene</li>
                            <li>Dijabetesno stopalo</li>
                            <li>Birgerova bolest, Rejnova bolest</li>
                            <li>Morbus Sudeck(posledica nošenja gipsa)</li>
                            <li>Odloženo srašćivanje kostiji(preloma)</li>
                            <li>Opekotine, promrzline, ugroženi kožni režnjevi</li>
                            <li>Rane nakon zračne terapije</li>
                            <li>Akutna reumatska upala zglobova</li>
                            <li>Sklerodermia</li>
                            <li>Multipla skleroza</li>
                            <li>Čir na želucu i dvanaestopalačnom crevu</li>
                            <li>Kronova bolest, ulcerozni kolitis</li>
                            <li>Srčana bolest, post infarktna stanja, Bai pasevi</li>
                            <li>Intervencije na krvnim sudovima</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <img className="koje-bolesti-leci-slika" src={o2} alt="O2"/>
                </div>
            </div>
*/