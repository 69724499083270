import { Component } from "react";

import "../style/NavigationComputer.css";

class Navigation extends Component{
    render(){
        return(
            <div className="col-lg-7 col-md-9 col-12">
                <nav>
                    <ul className="nav menu navigation-list">
                        <li><a href="#home">Početak</a></li>
                        <li><a href="#about-us">O nama</a></li>
                        <li><a href="#cure">Lečenje</a></li>
                        <li><a href="#payment">Plaćanje</a></li>
                        <li><a href="#contact">Kontakt</a></li>
                    </ul>
                </nav>
            </div>
        );
    }
}


export default Navigation;