import React, { useState, useEffect } from 'react';
import "../style/ScrollTop.css";

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when user scrolls down certain distance
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set up event listeners and clean up on unmount
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Scroll to top when button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className={`scroll-to-top ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
             <a id="scrollUp" href="#top" style={{"position": "fixed", "zIndex": "2147483647"}}>
                <span>
                    <i class="bi bi-arrow-up-circle-fill"></i>
                </span>
            </a>
        </div>
       
    );
};

export default ScrollToTop;
