import React from 'react';
import { useState } from 'react';

import "../style/NavigationPhone.css";

const MobileNav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log("Toggle Menu");
        setIsOpen(!isOpen);
        console.log("Is Open:", isOpen);

        const navbarToggle = document.querySelector('.navbar-toggle');
        navbarToggle.classList.toggle('cross');
    };

    return (
        <div className='mobile-nav'>
            <nav className="navbar">
                <div className={isOpen ? "navbar-toggle cross" : "navbar-toggle"} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <ul className={isOpen ? "navbar-menu open" : "navbar-menu"}>
                    <li><a href="#home">Početak</a></li>
                    <li><a href="#about-us">O nama</a></li>
                    <li><a href="#cure">Lečenje</a></li>
                    <li><a href="#payment">Plaćanje</a></li>
                    <li><a href="#contact">Kontakt</a></li>
                </ul>
            </nav>
        </div>
    );
};

export default MobileNav;