import { Component } from "react";

import "../style/Fact.css";
import Slideshow from "./SlideShow";
import o2 from "../images/komora2.jpg";

const FactKontak = () => {
    return(
        <section class="call-action overlay">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-12">
						<div class="content">
							<h2>Potrebno vam je usluga lečenja hiperbaričnom komorom ili ortopedije?</h2>
							<p>Kontaktirajte nas telefonom kako bi dobili više informacija o navedenim uslugama.</p>
							<div>
								<a href="#contact" class="btn">Kontak</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default FactKontak;
