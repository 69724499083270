import { Component } from "react";
import NavigationComputer from "./NavigationComputer";
import NavigationPhone from "./NavigationPhone";

import "../style/Header.css";


const Header = (props) => {

    return(
        <header>
            <div className="header-inner container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-3 col-12 align-content-center">
                        <div className="mobile-nav">
                            <div className="logo">
                                <h2 className="brand-name">{props.brand}</h2>
                            </div>
                            <NavigationPhone />
                        </div>
                    </div>
                    <NavigationComputer />
                </div>
            </div>
        </header>
        );
}

export default Header;