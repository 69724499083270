import { Component } from "react";

import "../style/Fact.css";
import Slideshow from "./SlideShow";
import o2 from "../images/komora2.jpg";

const FactKiseonik = () => {
    return(
        <section class="call-action overlay" data-stellar-background-ratio="0.5" style={{"bsackgroundPosition": "-25px -50.6625px"}}>
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-12">
						<div class="content">
							<h2>Šta je terpaija kiseonikom?</h2>
							<p>Terapija kiseonikom je najsavremeniji tretman koji uključuje udisanje čistog kiseonika u komori pod pritiskom. Time se povećava količina kiseonika u krvi i tkivima, što ubrzava procese ozdravljenja i regeneracije organizma.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default FactKiseonik;
