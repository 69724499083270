import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import KojeBolestiLeci from "./KojeBolestiLeci";
import Kontakt from "./Kontakt";
import Overview from "./Overview";
import FactKontak from "./FactKontak";
import FactKiseonik from "./FactKiseonik";
import Baromedic from "./Baromedic";
import ScrollTop from "./ScrollTop";
import FactPlaćanje from "./FactPlaćanje"

import "../style/App.css";

const App = () => {
    return(
        <div>
            <Header brand="Ortobaro"/>
            <Home />
            <Overview />
            <FactKontak />
            <Baromedic />
            <FactKiseonik />
            <KojeBolestiLeci />
            <FactPlaćanje />
            <Kontakt />
            <Footer />
            
            <ScrollTop />
        </div>
    );
}

export default App;