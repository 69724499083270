import komore from "../images/komore.jpg";
import komora1 from "../images/komora1.jpg";
import komora2 from "../images/komora2.jpg";
import objekat from "../images/slika_objekta.jpg";
import hodnik1 from "../images/hodnik1.jpg";
import hodnik2 from "../images/hodnik2.jpg";
import kancelarija from "../images/kancelarija.jpg";
import orto from "../images/orto.jpg";
import previjanje from "../images/previjanje.jpg";

function Slideshow() {
   
  return (
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src={objekat} alt="First slide" />
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={komore} alt="Second slide" />
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={komora1} alt="Third slide" />
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={hodnik2} alt="Third slide" />
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={orto} alt="Third slide" />
        </div>
      </div>

      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
</div>
       
 
  );
}

export default Slideshow;