import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked, faBuilding, faPhoneAlt, faUserMd, faAt } from "@fortawesome/free-solid-svg-icons";

import "../style/Kontakt.css";

const Kontakt = () => {
    return(
        <section className="appointment" id="contact">
			<div className="container">
            <div class="row">
					<div class="col-lg-12">
						<div class="section-title">
							<h2>Kontakt</h2>
							<p>Za više informacija o našim uslugama možete pozvati telefonom</p>
						</div>
					</div>
				</div>
                
                <div className="row">
                    <div className="col-lg-6 col-12 h-100">
                        <div className="bg-light rounded d-flex align-items-center p-4 mb-4">
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{"width": "30px", "height": "30px"}}>
                                <FontAwesomeIcon icon={faBuilding} className="text-primary"></FontAwesomeIcon>
                            </div>
                            <div className="ms-4">
                                <h5 className="mb-0">Ortobaro</h5>
                                <p className="mb-2" style={{fontSize: "12px"}}>Specijalistička ordinacija za hiperbaričnu medicinu iz oblasti baromedicine.</p>
                            </div>
                        </div>
                        
                        <div className="bg-light rounded d-flex align-items-center p-4 mb-4">
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{"width": "30px", "height": "30px"}}>
                                <FontAwesomeIcon icon={faMapMarked} className="text-primary"></FontAwesomeIcon> 
                            </div>
                            <div className="ms-4">
                                <p className="mb-0">Gilje, Drumska 154, Mijatovac</p>
                                <p className="mb-2" style={{fontSize: "12px"}}>Na pola puta između Jagodine i Ćuprije.</p>
                            </div>
                        </div>

                        <div className="bg-light rounded d-flex align-items-center p-4 mb-4">
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{"width": "30px", "height": "30px"}}>
                                <FontAwesomeIcon icon={faPhoneAlt} className="text-primary"></FontAwesomeIcon>
                            </div>
                            <div className="ms-4">
                                <p className="mb-0">064-11-35-828</p>
                                <p className="mb-0">064-21-21-430</p>
                            </div>
                        </div>

                        <div className="bg-light rounded d-flex align-items-center p-4 mb-4">
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{"width": "30px", "height": "30px"}}>
                                <FontAwesomeIcon icon={faAt} className="text-primary"></FontAwesomeIcon>
                            </div>
                            <div className="ms-4">
                                <p className="mb-0">ortobaro@gmail.com</p>
                            </div>
                        </div>

                        <div className="bg-light rounded d-flex align-items-center p-4 mb-4">
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{"width": "30px", "height": "30px"}}>
                                <FontAwesomeIcon icon={faUserMd} className="text-primary"></FontAwesomeIcon>
                            </div>
                            <div className="ms-4">
                            <p className="mb-0">Dr Momčilo Marić</p>
                            </div>
                        </div>

                    </div>
                
                    <div className="col-lg-6 col-12 bg-light rounded mb-4 d-flex align-items-center p-4">
                        <iframe className="kontakt-mapa"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d704.9759726177533!2d21.315391564766994!3d43.953927411763786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51c178f429c8b06d!2zNDPCsDU3JzEzLjkiTiAyMcKwMTgnNTUuMyJF!5e1!3m2!1ssr!2srs!4v1642633941730!5m2!1ssr!2srs" 
                                width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" title="lokacija objekta">
                        </iframe>
                    </div>
                </div>
       
                
			</div>
		</section>
        );
}

export default Kontakt;