import { Component } from "react";
import "../style/Baromedic.css";
import o2 from "../images/slika_objekta.jpg";


const Baromedic = () => {
    return(
<section class="why-choose" id="about-us">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="section-title">
							<h2>Ortobaro</h2>
							<p>Specijalistička ordinacija za Ortopediju i Baromedicinu</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 col-12">
				
						<div class="choose-left">
							<h3>Čime se bavimo?</h3>
							<p>Ortobaro je specijalistička ordinacija za ortopediju i baromedicinu koja pruža usluge lečenja povreda i bolesti organizma.</p>	
							
							<p>U ordinaciji ortopedije i traumatologije možete da dobijete sve vrste pregleda, intervencija i konsultacija iz oblasti povreda i bolesti koštanog zglobnog sistema.</p>

							<p>Naša ordinacija je opremljena najsavremenijim hiperbaričnim komorama koje omogućavaju pacijentima da dobiju sve prednosti terapije kiseonikom na jednom mestu.
								Ova terapija je efikasna u lečenju i regeneraciji organizma od različitih bolesti i povreda.</p>
							
							<div class="row">
								<div class="col-lg-6">
									<ul class="list p-0">
										<li><i class="bi bi-caret-right-fill"></i>Regeneracija ćelija</li>
										<li><i class="bi bi-caret-right-fill"></i>Smanjenje infekcija i upala</li>
										<li><i class="bi bi-caret-right-fill"></i>Poboljšanje cirkulacije</li>
									</ul>
								</div>
								<div class="col-lg-6">
									<ul class="list p-0">
										<li><i class="bi bi-caret-right-fill"></i>Lečenje hroničnih bolesti</li>
										<li><i class="bi bi-caret-right-fill"></i>Lečenje autoimunih bolesti</li>
										<li><i class="bi bi-caret-right-fill"></i>Lečenje neuroloških bolesti</li>
									</ul>
								</div>
							</div>
						</div>
					
					</div>

					<div class="col-lg-6 col-12">
						<div class="choose-right"></div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default Baromedic;

