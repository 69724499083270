import "../style/FactPlaćanje.css";



const FactPlaćanje = () => {
	return(
		<section class="call-action overlay" id="payment">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-12">
						<div class="content">
							<h2>Plaćanje na teret republičkog fonda ili o ličnom trošku</h2>
							<p>U hiperbaričnoj komori mogu se lečeti sva privatna lica o ličnom trošku, kao i svi pacijenti sa uputom izabranog lekara na teret republičkog fonda zdravstva
								po odobrenju konzilijarnijh komisija svih kliničkih centara. Ortopedske usluge se plaćaju isključivo o ličnom trošku.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default FactPlaćanje;