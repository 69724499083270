import { Component } from "react";

import "../style/Footer.css";

const  Footer = () => {

        return(
<div class="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s" style={{"visibility": "visibl"}}>
        <div class="container py-5">
            <div class="row justify-content-between g-5">
            <div class="col-lg-3 col-md-6 col-12">
							<div class="single-footer">
								<h5>O nama</h5>
								<p className="footer-text">Nudimo sve vrste specijalističkih pregleda, konsultacija, lečenja kao i post terapijsko praćenje pacijenata iz oblasti Baromedicine i Ortopedije</p>
							</div>
						</div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-footer" >
                        <h5 class="text-light mb-4">Adresa</h5>
                        <p class="mb-2 footer-text">Ortobaro</p>
                        <p class="mb-2 footer-text">Gilje, Drumska 154, Mijatovac</p>
                        <p class="mb-2 footer-text">064-11-35-828</p>
                        <p class="mb-2 footer-text">064-21-21-430</p>
                        <p class="mb-2 footer-text">ortobaro@gmail.com</p>
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-footer" >
                        <h5 class="text-light mb-4">Brzi Linkovi</h5>
                        <ul className="p-0">
                            <li><a class="quick-link footer-text mb-2" href="#home"><i class="bi bi-caret-right-fill"></i> Početna</a></li>
                            <li><a class="quick-link footer-text mb-2" href="#about-us"><i class="bi bi-caret-right-fill"></i> O Nama</a></li>
                            <li><a class="quick-link footer-text mb-2" href="#cure"><i class="bi bi-caret-right-fill"></i> Lečenje</a></li>
                            <li><a class="quick-link footer-text mb-2" href="#payment"><i class="bi bi-caret-right-fill"></i> Plaćanje</a></li>
                            <li><a class="quick-link footer-text mb-2" href="#contact"><i class="bi bi-caret-right-fill"></i> Kontakt</a></li>	
						</ul>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        © <span class="borsder-bottom">Ortobaro</span>, All Right Reserved.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        Developed By <span class="bosrder-bottom">Bogdan Marić</span>
                    </div>
                </div>
            </div>
        </div>
    </div> 
        );
}

export default Footer;
